/* eslint-disable */
<template>
  <div>
    <b-modal
      v-model="showModal"
      ok-only
      ok-title="Accept"
      centered
      size="xl"
      :hide-footer="true"
      :title="product.name || product.title"
      lazy
      static
    >
      <div v-if="product.categoryInformations">
        <!-- <v-jsoneditor
          v-model="categoryInformations"
          :options="options"
          height="600px"
          @error="errorJson"
        /> -->
        <quill-editor-snow style="display: none;" />
        <b-row
          class="my-2"
        >
          <b-col
            cols="6"
          >
            <p>Show:</p>
            <b-form-checkbox
              v-model="faqData.show"
              name="is-menu-visible"
              class="mr-0"
              switch
              inline
            />
          </b-col>
        </b-row>
        <b-row
          class="my-2"
        >
          <b-col
            cols="6"
          >
            <p>Titre:</p>
            <b-form-input
              v-if="faqData"
              v-model="faqData.title"
              class="mb-2"
            />
          </b-col>
          <b-col
            cols="6"
          >
            <p>Catégorie:</p>
            <v-select
              v-model="faqCategorySelect"
              placeholder="Catégorie"
              label="key"
              :options="faqCategories"
              :clearable="false"
              @input="setCategory"
            />
          </b-col>
          <b-col
            cols="12"
            class="primary"
          >
            <div>
              <app-collapse v-if="faqData.values">
                <app-collapse-item
                  v-for="(value, keyFaq) of faqData.values"
                  id="section"
                  :key="`${keyFaq}-value`"
                  :title="value.title || `Section ${keyFaq + 1}`"
                >
                  <b-row>
                    <b-col
                      cols="11"
                      class="primary my-4"
                    >
                      <h3><u><strong>Section {{ keyFaq + 1 }}:</strong></u></h3>
                      <p>Titre:</p>
                      <b-form-input
                        v-if="value"
                        v-model="value.title"
                        class="mr-4"
                        style="width: 100%"
                      />
                    </b-col>
                    <b-col
                      cols="1"
                      class="primary my-4"
                    >
                      <b-button
                        variant="primary"
                        class="btn-icon"
                        @click="deleteElementFaqV2(keyFaq)"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-col
                    v-for="(subSection, keySection) of value.sections"
                    :key="`${keySection}-section`"
                    class="ml-2"
                    cols="12"
                  >
                  <h3><u><strong>Sous-section {{ keySection + 1 }}:</strong></u></h3>
                    <b-row>
                      <b-col
                        cols="3"
                        class="primary my-4"
                      >
                        <p>Sous-titre:</p>
                        <b-form-input
                          v-if="subSection"
                          v-model="subSection.title"
                          class="mr-4"
                          style="width: 100%"
                        />
                      </b-col>
                      <b-col
                        cols="3"
                        class="primary my-4"
                      >
                        <p>Image:</p>
                        <b-form-input
                          v-if="subSection"
                          v-model="subSection.img"
                          class="mr-4"
                          style="width: 100%"
                        />
                      </b-col>
                      <b-col
                        cols="3"
                        class="primary my-4"
                      >
                        <p>Video (Lien youtube embed):</p>
                        <b-form-input
                          v-if="subSection"
                          v-model="subSection.video"
                          class="mr-4"
                          style="width: 100%"
                        />
                      </b-col>
                    </b-row>
                    <b-col
                      cols="8"
                      class="primary my-4"
                    >
                      <p>Contenu:</p>
                      <quill-editor
                        v-model="subSection.content"
                        class="mb-2"
                        :options="snowOption"
                      />
                    </b-col>
                    <b-col
                      cols="1"
                      class="primary my-4"
                    >
                      <b-button
                        variant="primary"
                        class="btn-icon"
                        @click="value.sections.splice(keySection, 1);"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </b-col>
                    <b-col
                      v-if="keySection === value.sections.length - 1"
                      span="2"
                      class="primary d-flex justify-content-end"
                    >
                      <b-button
                        variant="primary"
                        @click="addSection(keyFaq)"
                      >
                        <span class="text-nowrap">Ajouter une sous section</span>
                      </b-button>
                    </b-col>
                  </b-col>
                </app-collapse-item>
                <app-collapse-item
                  title="Document fournisseur interne"
                >
                  <b-row>
                    <b-col
                      class="ml-2"
                      cols="12"
                    >
                      <p>Contenu:</p>
                      <quill-editor
                        v-model="internFaqData"
                        class="mb-2"
                        :options="snowOption"
                      />
                    </b-col>
                  </b-row>
                </app-collapse-item>
                <app-collapse-item
                  title="Avis section FAQ"
                >
                  <b-row>
                    <b-col
                      v-for="(review, keyReview) of specificReviews"
                      :key="`${keyReview}-review`"
                      class="ml-2"
                      cols="12"
                    >
                      <b-row>
                        <b-col
                          cols="6"
                          class="primary my-4"
                        >
                          <p>titre:</p>
                          <b-form-input
                            v-if="review"
                            v-model="review.title"
                            class="mr-4"
                            style="width: 100%"
                          />
                        </b-col>
                        <b-col
                          cols="6"
                          class="primary my-4"
                        >
                          <p>Nom du client:</p>
                          <b-form-input
                            v-if="review"
                            v-model="review.name"
                            class="mr-4"
                            style="width: 100%"
                          />
                        </b-col>
                      </b-row>
                      <b-col
                        cols="8"
                        class="primary my-4"
                      >
                        <p>Contenu:</p>
                        <quill-editor
                          v-model="review.content"
                          class="mb-2"
                          :options="snowOption"
                        />
                      </b-col>
                      <b-col
                        cols="1"
                        class="primary my-4"
                      >
                        <b-button
                          variant="primary"
                          class="btn-icon"
                          @click="deleteReview(keyReview)"
                        >
                          <feather-icon icon="Trash2Icon" />
                        </b-button>
                      </b-col>
                    </b-col>
                    <b-col
                      v-if="specificReviews.length < 3"
                      span="2"
                      class="primary d-flex justify-content-end"
                    >
                      <b-button
                        variant="primary"
                        @click="addReview()"
                      >
                        <span class="text-nowrap">Ajouter un avis</span>
                      </b-button>
                    </b-col>
                  </b-row>
                </app-collapse-item>
              </app-collapse>
              <b-col
                span="12"
                class="primary d-flex justify-content-end"
              >
                <b-button
                  class="mt-4"
                  variant="primary"
                  @click="() => faqData.values.push({title: '', sections: [{title: '', content: ''}]})"
                >
                  <span class="text-nowrap">Ajouter une ligne FAQ</span>
                </b-button>
              </b-col>
            </div>
          </b-col>
        </b-row>
        <b-row
          class="my-2"
        >
          <b-col
            span="12"
            class="primary d-flex justify-content-end"
          >
            <b-button
              variant="primary"
              :disabled="loading"
              @click="updateValue(product.categoryInformations)"
            >
              <span class="text-nowrap">Enregistrer</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-card>
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="3"
          md="3"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <v-select
            v-model="country"
            placeholder="Pays"
            label="Pays"
            :options="countryOptions"
            :clearable="false"
            class="invoice-filter-select mt-1"
          />
        </b-col>
      </b-row>
    </b-card>
    <div class="mt-1 mb-3">
      <b-row>
        <b-col
          cols="12"
        >
          <div class="px-2 py-1 navbar-light header-navbar rounded-lg shadow d-flex align-items-center justify-content-start">
            <feather-icon
              icon="SearchIcon"
              class="mr-50 text-primary"
              size="20"
            />
            <b-input-group class="input-group-merge shadow-none">
              <b-form-input
                placeholder="Rechercher un produit..."
                style="height: auto;"
                class="border-0 shadow-none"
                @input="filter"
              />
            </b-input-group>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-card title="Vous pouvez modifier les produits directement ici.">
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <b-row v-else class="match-height">
        <b-col
          v-for="(pdct, keyP) in products"
          :key="keyP"
          md="3"
          lg="3"
          class=""
        >
          <b-card
            class="ecommerce-card"
            no-body
          >
            <div
              v-if="pdct.images && pdct.images.length"
              class="item-img text-center"
            >
              <b-img
                :alt="`${pdct.title}`"
                fluid
                class="card-img-top"
                style="height: 180px; object-fit: cover"
                :src="pdct.images[0].src"
              />
            </div>

            <!-- pdct Details -->
            <b-card-body>
              <div class="item-wrapper">
                <div class=" d-flex justify-content-between">
                  <h5 class="item-price text-primary text-lighten-2">
                    {{ pdct.price }} €
                  </h5>
                  <h5 class="item-price text-primary text-secondary">
                    {{ pdct.id }}
                  </h5>
                </div>
              </div>
              <h4 class="item-name">
                {{ pdct.title || pdct.name }}
              </h4>
              <!-- <b-card-text class="item-description">
                {{ product.short_description }}
              </b-card-text> -->
            </b-card-body>

            <!-- Product Actions -->
            <div class="item-options text-center">
              <b-button
                variant="flat-primary"
                tag="a"
                class="btn-cart mb-1"
                @click="openModal(pdct)"
              >
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />
                <span>Modifier le produit</span>
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BModal,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BButton,
  BFormInput,
  BInputGroup,
  BSpinner,
  BFormCheckbox,
} from 'bootstrap-vue'
import QuillEditorSnow from '@core/components/quill-editor/QuillEditorCustom.vue'
import { Quill, quillEditor } from 'vue-quill-editor'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vSelect from 'vue-select'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import faqCategories from '@utils/faqCategory.json'

export default {
  components: {
    vSelect,
    BCard,
    BModal,
    BRow,
    BCol,
    BImg,
    BButton,
    BCardBody,
    BSpinner,
    QuillEditorSnow,
    BFormInput,
    BInputGroup,
    AppCollapse,
    AppCollapseItem,
    BFormCheckbox,
    quillEditor,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,
  },
  watch: {
    country: {
      deep: true,
      handler: 'getProducts',
    },
  },
  data() {
    return {
      faqCategories,
      faqCategorySelect: '',
      baseProducts: [],
      pinterestProduct: false,
      products: [],
      product: {},
      showModal: false,
      file: null,
      loading: false,
      disabledImport: false,
      options: { mode: 'code' },
      categoryInformations: [],
      country: 'FR',
      countryOptions: ['ES', 'FR', 'DE', 'IT', 'PT', 'NL', 'EN', 'BB', 'DK'],
      faqData: {},
      internFaqData: '',
      snowOption: {
        theme: 'snow',
        modules: {
          toolbar: ['bold', 'italic', 'underline', 'link'],
          clipboard: {
            matchVisual: false,
          },
        },
      },
      whithoutOption: {
        theme: 'snow',
        modules: {
          toolbar: [],
        },
      },
    }
  },
  async mounted() {
    try {
      const Block = Quill.import('blots/block')
      Block.tagName = 'div'
      Quill.register(Block, true)
      await this.getProducts()
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    setCategory() {
      const faqMapped = { title: '', values: this.faqCategorySelect.details.map(f => ({ title: f.title, sections: f.value?.length ? f.value.split(',').map(s => ({ title: s, content: '' })) : [{ title: '', content: '' }] })) }
      this.faqData = faqMapped
    },
    async getProducts() {
      try {
        this.loading = true
        const { products } = await this.$http.get('/admin/list-data-product-v2', { params: { filter: { country: this.country } } })
        this.products = products
        this.baseProducts = products
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    },
    addBloc() {
      this.categoryInformations.lg.seo.blocs.push({ title: '', text: '' })
    },
    convertHtml(html) {
      // eslint-disable-next-line newline-per-chained-call
      return html?.replaceAll('<span style="color: rgb(0, 0, 0);">', '')?.replaceAll('</span>', '')?.replaceAll('style="color: rgb(0, 0, 0);"', '')
      // this.content = html
    },
    addSection(key) {
      this.faqData.values[key].sections.push({ title: '', content: '' })
    },
    addReview() {
      this.specificReviews.push({ title: '', content: '', name: '' })
      this.$forceUpdate()
    },
    deleteReview(keyReview) {
      this.specificReviews.splice(keyReview, 1)
      this.$forceUpdate()
    },
    openModal(product) {
      this.product = product
      this.categoryInformations = product.categoryInformations
      this.faqData = this.categoryInformations.lg.faqV2
      if (!this.faqData) {
        this.faqData = { title: '', values: [{ title: '', sections: [{ title: '', content: '' }] }] }
      }
      this.internFaqData = this.categoryInformations.lg.internFaq
      if (!this.internFaqData) {
        this.internFaqData = ''
      }
      this.specificReviews = this.categoryInformations.lg.specificReviews
      if (!this.specificReviews) {
        this.specificReviews = [{ title: '', content: '', name: '' }]
      }
      this.showModal = true
    },
    async updateValue() {
      try {
        this.loading = true
        if (this.faqData?.values) {
          this.faqData.values = this.faqData.values?.map(v => ({ ...v, sections: v?.sections.map(s => ({ title: s.title, content: this.convertHtml(s.content) })) }))
        }
        this.categoryInformations.lg.faqV2 = this.faqData
        this.categoryInformations.lg.internFaq = this.internFaqData
        this.categoryInformations.lg.specificReviews = this.specificReviews
        await this.$http.put(`admin/products/${this.product._id}/v2`, { categoryInformations: this.categoryInformations })
        await this.getProducts()
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Enregistrement réussi',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    deleteElementFaqV2(key) {
      console.log('fsqjkdfns', this.categoryInformations.lg.faqV2.values)
      this.categoryInformations.lg.faqV2.values.splice(key, 1)
      console.log('dfd', this.categoryInformations.lg.faqV2.values)
    },
    async upload(f) {
      if (!this.file) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Vous devez renseigner tous les champs.',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
      } else {
        console.log(f)
        setTimeout(() => {}, 2000)
        const form = new FormData()
        form.append('file', this.file, this.file.name)
        try {
          await this.$http.post(`/importAll/import-content/${this.product._id}`, form)
          this.file = null
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Votre contenu a bien été importé.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          await this.getProducts()
        } catch (err) {
          console.log(err)
        }
      }
    },
    async importWoo() {
      try {
        this.disabledImport = true
        await this.$http.get(`admin/products/${this.product._id}/import-woo/?country=${this.country}&id=${this.product.id}`)
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Import réussi',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      } catch (err) {
        console.log(err)
      } finally {
        this.disabledImport = false
      }
    },
    errorJson(err) {
      console.log(err)
    },
    filter(e) {
      this.filterQuery = e
      if (!e || e === '') {
        this.products = this.baseProducts
      } else {
        const value = e
        this.products = this.baseProducts.filter(product => product.name.toLowerCase().includes(value.toLowerCase()))
      }
    },
  },
}
</script>

<style>
 .jsoneditor-poweredBy {
   display: none;
 }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
